// external fonts
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300i,400,400i,700,700i);


@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon.eot');
    src: url('../fonts/icomoon.eot?#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.woff2') format('woff2'),
        url('../fonts/icomoon.woff') format('woff'),
        url('../fonts/icomoon.ttf') format('truetype'),
        url('../fonts/icomoon.svg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-right:before {
    content: "\e900";
}
.icon-chevron-left:before {
    content: "\e901";
}
.icon-chevron-down:before {
    content: "\e902";
}
.icon-chevron-up:before {
    content: "\e903";
}
.icon-search:before {
    content: "\e908";
}
.icon-lock:before {
    content: "\e904";
}
.icon-phone:before {
    content: "\e905";
}
.icon-linkedin:before {
    content: "\e906";
}
.icon-twitter:before {
    content: "\e907";
}