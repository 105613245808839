// --------------------------------------------------
// Global settings
// --------------------------------------------------

// Colors
@black: #000;
@white: #FFF;

@blue: #1c9cd9;
@orange: #f39204;
@grey: #eaedf7;
@grey-medium: #808080;
@darkblue: #193964;
@darkblue-dark: #1b2745;
@grey-bg: #f2f2f2;
@lightblue: #d9effa;

@green-header: #70bd85;
@green-bg: #b2d8b7;

@blue-header: #2f66a1;
@blue-bg: #36b1e6;

@grey: #e5e5e5;
@darkgrey: #555555;

@banner-text: @black;

@color1: @blue;
@color2: @orange;
@contrast1: @grey;
@contrast2: @white;
@textcolor: @black;
@titlecolor: @darkblue;


// mix with white: tint()
// mix with black: shade()


// Typography
@contentfont: 'Source Sans Pro', sans-serif;
@titlefont: 'Source Sans Pro', sans-serif;;
@subtitlefont: @contentfont;


// Templates
@leegheight: 100px; //400px;

// Breakpoints
/* Extra small vertical devices (phones, less than 480px) */
@phone:                     ~"only screen and (max-width: @{screen-xs-min})";

/* Extra small devices (horizontal phones, less than 768px) */
@xsmall:                    ~"only screen and (min-width: @{screen-xs-min}) and (max-width: @{screen-xs-max})";

/* Small devices (tablets, 768px and up) */
@small:                     ~"only screen and (min-width: @{screen-sm-min}) and (max-width: @{screen-sm-max})";

/* Medium devices (desktops, 992px and up) */
@medium:                    ~"only screen and (min-width: @{screen-md-min}) and (max-width: @{screen-md-max})";

/* Large devices (large desktops, 1200px and up) */
@large:                     ~"only screen and (min-width: @{screen-lg-min})";

/* Higher pixel density screens */
@retina:                    ~"only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi)";

@mobile: @phone, @xsmall;
@nonmobile: @small, @medium, @large;

