section.contact-132 {
    //padding-top: 0;
    //padding-bottom: 0;


    .col1 {
        .make-sm-column(6);
        .make-md-column(6);
        @media @phone, @xsmall {
            margin-bottom: 30px;
        }
    }

    .col2 {
        .make-sm-column(6);
        .make-md-column(6);
        label {
            text-align: left;
        }
    }



}

section.contact-133 {
    .col2 {
        .make-sm-column(12);
        .make-md-column(8);
        label {
            text-align: left;
        }
    }



}
