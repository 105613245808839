* {
    .lh-box-sizing(border-box);
}

html {
    font-size: 62.5%;
}

html,
body {
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    height: 100%;

}

body {
    font-family: @contentfont;
    color: @textcolor;
    .font-size(16);
    letter-spacing: 0.025em;
    line-height: 1.8;

    @media @phone, @xsmall {
        //.font-size(18);
    }
}

a {
    color: @color1;
    text-decoration: none;

    &:link {
        text-decoration: none;
        outline: 0;
    }

    &:visited {
        text-decoration: none;
        outline: 0;
    }

    &:active {
        text-decoration: none;
        outline: 0;
    }

    &:hover {
        text-decoration: underline;
        outline: 0;
    }
    .filelist & {

        &[href $='.pdf'],
        &[href $='.xls'],
        &[href $='.xlsx'],
        &[href $='.doc'],
        &[href $='.docx'],
        &[href $='.zip'],
        &[href $='.ppt'],
        &[href $='.pptx'] {
            &:not(.no-icon){
                &:before {
                    .icon;
                    display: inline-block;
                    margin-right: 5px;
                    font-size: 120%;
                    vertical-align: text-bottom;
                    content: "\e907";
                }
            }
        }
        &[href $='.pdf']:before { content: '\f1c1' !important;}
        &[href $='.xls']:before,
        &[href $='.xlsx']:before { content: '\f1c3' !important;}
        &[href $='.doc']:before,
        &[href $='.docx']:before { content: '\f1c2' !important;}
        &[href $='.ppt']:before,
        &[href $='.pptx']:before { content: '\f1c4' !important;}
        &[href $='.jpg']:before,
        &[href $='.png']:before { content: '\f1c5' !important;}
        &[href $='.zip']:before { content: '\f1c6' !important;}
    }
}

.header {
    background: @white;
    position: relative;
    z-index: 25;
    .clearfix();

    .sticker {
        &.stuck {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            background-color: @white;
            z-index: 5;
            .logo img {
                max-height: 80px;
            }
            .header-options {
                display: none;
            }
            .mainmenu {
                margin-top: 15px;
            }
            &:after {
                position: absolute;
                content: '';
                left: 50%;
                width: 100%;
                margin-left: -50%;
                bottom: 0;
                height: 20px;
                background-color: rgba(155, 155, 155, 0.25);
                border-radius: 50%;
                box-shadow: rgba(0, 0, 0, 0.4) 0 5px 10px;
                clip: rect(20px, auto, 50px, 0);
            }
        }
    }

    @media @phone, @xsmall {
    }
    @media @small, @medium {
        .container {
            padding: 0;
        }
    }

    .logo {
        position: relative;
        z-index: 20;
        .make-xs-column(8);
        .make-sm-column(2);
        .make-md-column(2);
        padding: 0;
		margin: 0;
        a {
            display: block;
            height: 100%;
        }
        img {
            max-width: 100%;
            .lh-transition(all 0.15s ease);
        }

        @media @small {
            //margin-top: 25px;
        }

        @media @phone, @xsmall {
            height: auto;
            //padding-right: 15px;
            //text-align: center;
            img {
                max-height: 70px;
            }
        }
        .clearfix;
    }

    .navbar-header {
        @media @small, @medium, @large {
            display: none;
        }
        .make-xs-column(4);
        margin: 10px 0;
        padding: 0;
        .navbar-toggle {
            margin-right: 0;
            border-radius: 0;
            border-color: @color1;
            .icon-bar {
                background-color: @color1;
            }
        }
        z-index: 15;
    }

}

.header-options {
    @media @phone, @xsmall {
        display: none;
    }
    .lh-transition(all 0.15s ease);

    .make-sm-column(10);
    .make-md-column(10);

	margin: 20px 0;
	
    text-align: right;
    .social {
        display: inline-block;
        a {
            &.linkedin:before {
                content: '\e906';
            }
            &.twitter:before {
                content: '\e907';
            }
            &:before {
                .icon;
                .lh-border-radius(50%);
                display: inline-block;
                width: 24px;
                height: 24px;
                line-height: 20px;
                text-align: center;
                border: 2px solid @white;
                color: @color1;
                margin-right: 20px;
                vertical-align: middle;
            }
            &:hover {
                color: @color2;
                text-decoration: none;
                &:before {
                    color: @white;
                    background-color: @color2;
                    border-color: @color2;
                }
            }
        }
    }
    .telephone {
        display: inline-block;
        a {
            display: inline-block;
            color: @grey-medium;
            margin-right: 15px;
            &:before {
                .icon;
                content: '\e905';
                .lh-border-radius(50%);
                display: inline-block;
                width: 24px;
                height: 24px;
                line-height: 20px;
                text-align: center;
                border: 2px solid @color1;
                color: @color1;
                margin-right: 10px;
                vertical-align: middle;
            }
            &:hover {
                color: @color2;
                text-decoration: none;
                &:before {
                    color: @white;
                    background-color: @color2;
                    border-color: @color2;
                }
            }
        }
    }

}

.searchform {
    .header-options & {
        display: inline-block;
        vertical-align: middle;
        @media @phone, @xsmall {
            display: none;
        }
        border: 1px solid @grey-medium;
        .lh-border-radius(15px);
        input {
            border: 0;
            background: none;
        }
        button {
            border: 0;
            background: none;
            border-left: 1px solid @grey-medium;
        }
    }
    input {
        float: left;
        width: 220px;
        padding: 0 10px;
        border: 1px solid @grey-medium;
        border-right: none;
        background: @white;
        height: 28px;
        vertical-align: middle;
        -webkit-appearance: none;
        .lh-border-radius(0);
    }
    button {
        float: left;
        width: 30px;
        text-align: center !important;
        border: 1px solid @grey-medium;
        border-left: none;
        background: @white;
        border-left: 1px solid @grey-medium;
        height: 28px;
        vertical-align: middle;
        line-height: 1;
        -webkit-appearance: none;
        color: @grey-medium;
        &:after {
            .icon;
            content: '\e908';
        }
    }
}
/*

.searchresult {
    .make-md-column(10);
    padding: 0;
    margin: 0;

    .font-size(18) !important;
    font-weight: normal !important;

    h3 {
        .font-size(20) !important;
        margin: 0 !important;
    }
    p {

    }

    &:hover {
        color: @color1 !important;
        text-decoration: none !important;

        h3 {
            color: @color1 !important;
        }
    }
}
*/

.mainmenu {
    .make-sm-column(10);
    .make-md-column(10);

    position: relative;
    z-index: 10;

    @media @large {
        height: 50px;

    }
    @media @medium {
        height: 50px;
        padding: 0;

    }
    @media @small {
        height: 50px;
        padding: 0;
    }

    @media @phone, @xsmall {
        z-index: 20;
    }

    .navbar-header {
        background-color: @color1;
        margin: 0;

        .menu-header {
            @media @small, @medium, @large {
                display: none;
            }

            float: left;
            margin-left: 15px;
            max-width: 80%;

            a {
                display: inline-block;
                .font-size(16);
                line-height: 1;
                color: @color2;
                font-weight: bold;
                padding: 15px 0;
            }
        }

        .navbar-toggle {
            background-color: @color2;
            margin-right: 15px;

            .icon-bar {
                background-color: @color1;
            }
        }
    }
    .navbar-close {
        .clearfix;
        text-align: right;
        button {
            .font-size(40);
            color: @white;
            line-height: 1;
            padding: 0 10px;
            margin-right: 0;
        }
    }
    .menu {
        position: relative;
        z-index: 2;
        padding: 0;
        .lh-box-shadow(none);

        @media @small, @medium, @large {
        }

        @media @phone, @xsmall {
            padding: 5px 15px;

            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            overflow: auto;
            background: @darkblue;
            height: 100%;
        }
        > ul {
            width: 100%;
            .no-bullets;
            .clearfix;
            text-align: right;
            @media @phone, @xsmall {
                padding-bottom: 15px;
            }

            > li {
                position: relative;
                display: inline-block;
                .mobiel {
                    display: none;
                    @media @phone, @xsmall {
                        display: block;
                    }
                }
                @media @phone, @xsmall {
                    padding: 0;
                    display: block;
                    width: 100%;
                }


                a {
                    display: block;
                    color: @textcolor;
                    .font-size(18);
                    font-weight: bold;


                    //.lh-transition(background 0.15s ease);

                    @media @phone, @xsmall {
                        padding: 5px 15px;
                        height: auto;
                        text-align: left;
                        color: @white;
                        .font-size(18);
                    }

                    @media @small, @medium, @large {
                        line-height: 1.1;
                        text-align: center;
                        .lh-border-radius(4px);
                    }

                    @media @small {
                        .font-size(16);
                        line-height: 50px;
                        max-height: 50px;
                        padding: 0 7px;
                    }

                    @media @medium {
                        line-height: 50px;
                        max-height: 50px;
                        padding: 0 15px;
                    }

                    @media @large {
                        line-height: 50px;
                        max-height: 50px;
                        padding: 0 20px;
                    }

                }

                &:first-child {

                }

                &:hover {
                    > a {
                        text-decoration: none;
                        color: @color2;
                    }

                    ul {
                        display: block;
                    }
                }



                @media @small, @medium, @large {
                    display: inline-block;

                    > a {
                        //letter-spacing: 0.25px;
                    }

                    &.active {
                        > a {
                            background-color: @color2;
							color: @white;

                        }
                    }

                }

                ul {
                    .no-bullets;
                    //.lh-box-shadow(2px 2px 4px 0 rgba(0,0,0,0.2));
                    text-align: left;
                    //background-color: fade(@color1, 80%);
                    padding-top: 20px;
                    @media @small, @medium, @large {
                        display: none;

                        position: absolute;
                        left: 0;
                        top: 100%;
                        z-index: 15;
                        //background: @white;
                        min-width: 300px;
                        //border: 1px solid #bbb;
                        border-top: none;
                        //padding: 10px 0 0 0;

                        li {

                            a {
                                .lh-border-radius(0);
                                .font-size(16);
                                display: block;
                                text-align: left;
                                color: @darkgrey;
                                background: fade(@white, 90%);
                                .lh-transition(background 0.2s ease);
                                text-transform: none;

                                &:hover {
                                    color: @white;
                                    background-color: @black;
                                    text-decoration: none;
                                }
                            }
                        }
                    }

                    @media @phone, @xsmall {
                        margin: 0 0 15px 0;
                        padding-top: 0;
                        li {
                            a {
                                display: block;
                                line-height: 1.3;
                                text-transform: none;
                                padding: 5px 15px 5px 30px;
                                font-weight: 300 !important;
                            }
                        }
                    }

                }

                &:last-child {
                    ul {
                        left: auto;
                        right: 0;
                    }
                }

            }
        }
    }
}


.top-carrousel {
    position: relative;
    width: 100%;
    z-index: 0;

    @media @medium, @large {
		height: 460px;
    }
    @media @small {
        height: 340px;
    }
    @media @phone, @xsmall {
        height: 200px;
    }

    .bx-wrapper,
    .bx-viewport {
        display: block;
        height: 100%;
    }

    ul {
        .no-bullets;
        height: 100%;
        li {

            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            .container {
                position: relative;
                height: 100%;
                z-index: 2;
                padding: 0;
            }

            a {
                display: block;
                height: 100%;
                span:after {
                    display: inline-block;
                    content: 'lees meer';
                    .font-size(16);
                    color: @color1;
                    border: 2px solid @white;
                    padding: 6px 15px;
                    background-color: fade(@white, 80%);
                    .lh-border-radius(4px);
                    float: right;
                    margin-bottom: 10px;

                    @media @phone, @xsmall {
                        .font-size(16);
                        padding: 7px 30px;


                    }
                }

                &:hover {
                    span {
                        background: @color1;
                        &:after {
                            background-color: @color1;
                            color: @white;
                        }
                    }
                }
            }


            span {
                display: block;
                text-align: center;
                margin: 160px auto 0;
                @media @small {
                    margin: 80px auto 0;
                }
                @media @phone, @xsmall {
                    margin: 60px auto 0;
                }
                h4 {
                    font-weight: bold;
                    color: @white;
                    font-family: @titlefont;
                    line-height: 1.1;

                    @media @medium, @large {
                        .font-size(68);
                        text-shadow: 0 0 30px #000, 0 0 35px rgba(0, 0, 0, 0.5), 0 0 40px  rgba(0, 0, 0, 0.5);
                    }
                    @media @small {
                        .font-size(48);
                        text-shadow: 0 0 15px #000, 0 0 20px rgba(0, 0, 0, 0.5), 0 0 25px  rgba(0, 0, 0, 0.5);
                    }
                    @media @phone, @xsmall {
                        .font-size(24);
                    }
                }

                h6 {
                    color: @white;
                    font-weight: 300;
                    font-family: @titlefont;
                    line-height: 1.1;
                    font-style: italic;
                    @media @medium, @large {
                        .font-size(52);
                        text-shadow: 0 0 5px #000, 0 0 10px rgba(0, 0, 0, 0.5), 0 0 15px rgba(0, 0, 0, 0.5);
                    }
                    @media @small {
                        .font-size(34);
                        text-shadow: 0 0 5px #000, 0 0 8px rgba(0, 0, 0, 0.5), 0 0 12px rgba(0, 0, 0, 0.5);
                    }
                    @media @phone, @xsmall {
                        .font-size(16);
                    }
                }
            }
        }
    }
    .bx-controls {
        //position: relative;
        z-index: 20;

        @media @phone, @xsmall, @small {
            display: none;
        }

        .bx-controls-direction {
            a {
                background: fade(@white, 40%);
                text-indent: 0;
                width: 60px;
                height: 60px;
                margin-top: -25px;
                padding-top: 15px;
                text-align: center;
                text-decoration: none;
                &:before {
                    .icon;
                    .font-size(30);
                    color: @grey;

                }

                &.bx-prev {
                    left: 40px;

                    &:before {
                        content: '\f053';
                    }
                }
                &.bx-next {
                    right: 40px;

                    &:before {
                        content: '\f054';
                    }
                }

                &:hover {
                    background: fade(@color1, 70%);
                    &:before {
                        color: @white;
                    }

                }
                @media @small, @phone, @xsmall {
                    background: fade(@black, 30%);
                    &.bx-prev {
                        left: 0;
                    }
                    &.bx-next {
                        right: 0;
                    }

                }
            }

        }
    }
}
.header-image {

    //.container;
    background-position: center;
    background-size: cover;

    position: relative;
    width: 100%;
    z-index: 0;


    @media @medium, @large {
        height: 300px;
    }
    @media @small {
        height: 240px;

    }
    @media @phone, @xsmall {
        height: 150px;
    }
}

.bg-image {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 65%;
    height: 100%;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: cover;

}

.pageheader {
    position: relative;
    z-index: 9;

   hgroup {
       position: relative;
       z-index: 2;

        h1 {
            &:extend(.zerniq_wysiwyg h1 all);
            margin: 0;
        }
        span {
            &:extend(.zerniq_wysiwyg h4 all);
            color: @color1;
            text-transform: uppercase;
        }
    }
}

.non-visual {
    background-color: @color1;
    border-bottom: 1px solid #cccccc;
    height: 30px;
}

.breadcrumbs {
    clear: both;
    position: relative;
    z-index: 3;
    background: @white;
    .font-size(16);
    color: #ababab;
    font-family: @contentfont;
    text-transform: lowercase;

    @media @small, @medium, @large {
        margin-top: 10px;

        .header-image + &,
        .top-carrousel + & {
            margin-top: 0;
        }
    }

    @media @phone, @xsmall {
        .font-size(14);
    }


    .container {
        padding-top: 7px;
        padding-bottom: 7px;
        //padding-left: 0;
        //padding-right: 0;
        background: transparent;
        margin-bottom: 15px;
    }
    ul, ol {
        .no-bullets;

        li {
            display: inline-block;

            a {
                color: #ababab;
                &:hover {
                    color: @color1;
                    text-decoration: none;
                }
            }

            &:after {
                content: '|';
                display: inline-block;
                padding: 0 2px 0 5px;
            }

            &:last-child {
                color: #ababab;
                a {
                    color: #ababab;
                }
                &:after {
                    content: '';
                }
            }
        }
    }

    .sticky-wrapper + & {
        padding-top: 15px;
    }
}

.sidemenu {
    @media @phone, @xsmall {
        display: none;
    }

    ul {
        .no-bullets;
        li {
            border-bottom: 1px solid fade(@color1, 60%);
            a {
                position: relative;
                display: block;
                .font-size(18);
                font-weight: 300 !important;
                line-height: 1.1;
                color: @black !important;
                padding: 15px 8px 8px 8px !important;

                &:hover {
                    text-decoration: none !important;
                    background-color: fade(@color1, 20%);
                }
            }

            &.active {
                border-bottom: 0;
                > a {
                    background-color: fade(@color1, 83%);
                    color: @white !important;
                }
            }

            ul {
                border-top: 1px solid fade(@grey, 50%);
                li {
                    padding-left: 20px;
                    border-bottom: 1px solid fade(@grey, 50%);

                    &:last-child {
                        border: none;
                    }
                }
            }
        }
    }

    .page-home & {
        ul {
            li {
                a {

                }
            }
        }
    }
}


.content {
    clear: both;
    position: relative;
    z-index: 2;
    min-height: 200px;


    > section {
        padding-top: 25px;
        padding-bottom: 25px;

        p:last-child {
            margin-bottom: 0;
        }

        /*&:first-child {
            @media @small, @medium, @large {
                padding-top: 50px;
            }

            @media @phone, @xsmall {
                padding-top: 30px;
            }
        }*/

        @media @phone, @xsmall {
            //margin: 0 15px;
        }

        .block {
            .clearfix;
            //min-height: 50px;
            &:extend(.zerniq_wysiwyg all);

        }
        &:not(.wide){
            > .block {
                .container;
                padding: 0;
            }
        }
        &.wide {
            > .block {
                .container-fluid;
                padding: 0;
            }
        }


        &.first-block {
            .col-left {
                .make-sm-column(4);
                .make-md-column(3);
            }
            .col-right {
                .make-sm-column(8);
                .make-md-column(9);
            }
        }

        &.bgcolor-1 {
            background: @color1;
            *, a, .block-title {
                color: @white;
            }
            h1,h2,h3,h4 {
                color: @white;
            }
            ul {
                li {
                    &:before {
                        color: @white;
                    }
                }
            }

        }

        &.bgcolor-2 {
            background: #dedede;
            .banner {
                background-color: @white;
                border-top: 1px solid @darkgrey;
                border-right: 1px solid @darkgrey;
                border-left: 1px solid @darkgrey;
            }
        }

        &.bgcolor-3 {
            background: @lightblue;
        }



        // Homepage exceptions

        /*&:first-child {
            .page-home & {
                padding-top: 0;
                background: @grey;
                .block * {
                    color: @white !important;
                }
            }
        }*/

        &:not([class^='content-1']) .block-title {
            .make-sm-column(12);
        }
    }


    //.banners-120, .banners-121, .banners-122, .banners-123 {

    //}


    .image-block {
        min-height: 100px !important;
        background-position: center;
        background-size: cover;
    }
}

.addthis {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    text-align: center;

}

.fit-vids-style {
    max-height: 0;
}

.was-newslink {

   /* figure {
        .make-sm-column(4);
        height: 170px;
        background-position: center;
        background-size: cover;
    }*/
    padding-bottom: 40px;
    display: block;
    span {
        //.make-sm-column(8);
        position: relative;
        display: block;
        h3 {
            height: 26px;
            color: @color1 !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            .font-size(24) !important;
            margin: 0 0 4px 0 !important;
            text-transform: none !important;
        }
        time {
            .font-size(13) !important;
            color: #999999;
            margin-bottom: 3px !important;
        }
        p {
            .font-size(15) !important;
            font-weight: normal !important;
            margin: 0 !important;
            max-height: 81px;
            overflow: hidden;
            color: @textcolor;
        }

        &:after {
            display: inline-block;
            text-align: center;
            position: absolute;
            left: 50%;
            width: 32px;
            height: 32px;
            margin-left: -16px;
            margin-top: 10px;
            .icon;
            line-height: 32px;
            content: '\e901';
            color: @white;
            background-color: @color1;
            .lh-border-radius(20);
            .font-size(20) !important;
            font-weight: normal !important;
        }
    }

    &:hover {
        text-decoration: none !important;
        h3 {
            color: @color1 !important;
        }
        span:after {
            background: @color1;
        }
    }
}
/*.banner,
.newslink {
    @media @large {
        height: 520px;
    }
    @media @medium {
        height: 200px;

    }

    @media @small {
        height: 260px;
    }

    @media @phone, @xsmall {
        height: 220px;
        margin: 0 0 @grid-gutter-width 0;
    }
}*/
.content > section.news-single-item {
    .block {
        padding: 0 !important;
    }
    .fluid-width-video-wrapper {
        margin-bottom: 10px;
    }

    article {
        .make-sm-column(8);
        @media @phone, @xsmall {
            padding: 0 15px;
        }
        time {
            &:extend(.zerniq_wysiwyg h4 all);
            text-transform: uppercase;
            color: @color2;
            margin: 0;
        }
    }
    aside {
        .make-sm-column(4);
      figure {
        margin-bottom: 30px;
      }
      h4 {
        color: @color2;
      }
      .news-links {
        a {
          &:extend(.zerniq_wysiwyg a.plus all);
        }
      }
    }
}
.deurmat {
    position: relative;
    z-index: 2;
    background: @color1;
    color: @white;

    .container {
        padding-top: 30px;
        padding-bottom: 15px;
    }

    h3 {
        font-family: @titlefont;
        .font-size(42);
        font-weight: 400;
    }

    a {
        color: @white;
        &:hover {
            text-decoration: underline;
            color: @grey;
        }
    }

    .column {
        .make-sm-column(4);
        .make-md-column(4);

       /* @media @small {
            &:nth-child(3){
                clear: both;
            }
        }*/

        @media @phone, @xsmall {
            margin-bottom: 15px;
        }
      a {
        &[href ^='https://www.linkedin'],
        &[href ^='https://twitter'] {
          &:not(.no-icon){
            display: inline-block;
            overflow: hidden;
            text-indent: -9999px;
            &:before {
              .icon;
              float: left;
              margin: 0;
              display: inline-block;
              text-indent: 0;
              margin-right: 5px;
              font-size: 200%;
              vertical-align: text-bottom;
            }
          }
        }
        &[href ^='https://twitter']:before { content: '\e907' !important;}
        &[href ^='https://www.linkedin']:before { content: '\e906' !important;}
      }
    }

    p {
        margin: 0 0 20px 0;
    }

    p + ul {
        margin-top: -20px;
    }

    ul {
        .no-bullets;
        list-style-type: none;
        margin-bottom: 10px;

        li {
            position: relative;
          a {
            color: @white;
            .font-size(22);
              display: block;
              @media @small, @mobile {
                  .font-size(18);
              }
            font-weight: bold;
            text-transform: uppercase;
            &:before {
              .icon;
              content: '\e900';
              color: @darkblue;
              padding-right: 8px;
            }
            &:hover {
              text-decoration: none;
              color: @darkblue;
            }
          }
        }
    }

  .copyright {
    text-align: center;
  }

}
.footer {
    position: relative;
    z-index: 2;
    height: 74px;
    margin-bottom: 26px;
    background-image: url(../images/footer.jpg);

    .block {
       .clearfix;
        &:extend(.zerniq_wysiwyg all);
       .container;
        padding: 0;
    }

    .font-size(14);

    .copyright {
        .make-sm-column(6);
        color: @white;
    }
    .links {
        .make-sm-column(6);
        color: @white;
        text-align: right;

        a + a {
            margin-left: 20px;
        }
        ul {
            .no-bullets;
            li {
                display: inline-block;
                margin-left: 12px;
            }
        }
    }

}

.content {
    .twitter-feed {
        h3 a {
            display: block;
            text-decoration: none;
            &:after {
                .icon;
                float: right;
                content: '\e907';
            }
        }
        .tweet {
            margin-bottom: 15px;
            img {
                float: left;
                margin-right: 15px;
            }
            p {
                overflow: hidden;
            }
        }
    }
    > section.partner-block-logorail {
        .col {
            .make-xs-column(3);
            width: 20%;
            text-align: center;
            padding: 0;
            i {
                .font-size(120);
                color: @color1;
                line-height: 200px;
                @media @small {
                    .font-size(80);
                    line-height: 150px;
                }
                @media @mobile {
                    .font-size(24);
                    line-height: 64px;
                }
            }
            @media @mobile {
                img {
                    max-height: 64px;
                    width: auto;
                }
            }
        }

    }
    > section.partner-block-zoekwordt {
        h4 {
            text-align: center;
            color: @white;
            .font-size(40);
            @media @small {
                .font-size(32);
            }
            @media @mobile {
                .font-size(24);
            }
            padding: 5px 0;
            font-weight: normal;
            margin: 0;
        }
        .zoeken {
            .make-sm-column(6);
            > div {
                .clearfix;
                background-color: @blue-bg;
                h4 {
                    background-color: @blue-header;
                }
                figure {
                    .make-xs-column(6);
                    padding: 0;
                    background-size: cover;
                    background-image: url(../images/kaart.jpg);
                    background-position: center center;
                    height: 192px;
                }
                form {
                    .make-xs-column(12);
                    .make-sm-column(6);
                    padding: 30px 15px 15px 15px;
                }
            }
            @media @mobile {
                margin-bottom: 15px;
                figure {
                    display: none;
                }
                form {

                }
            }

        }
        .worden {
            .make-sm-column(6);
            > div {
                .clearfix;
                background-color: @green-bg;

                h4 {
                    background-color: @green-header;
                }
                figure {
                    .make-xs-column(4);
                    padding: 30px 15px;
                    text-align: center;
                    img {

                    }
                }
                .info {
                    .make-xs-column(8);
                    color: @white;
                    padding: 30px 15px;
                }
            }
        }
    }
    > section.partner-block-logos {
        .partners {
            .make-sm-column(10);
            .col-centered;
            .bx-wrapper {
                margin: 0;
            }
            .bx-controls-direction {
                @media @mobile {
                    display: none;
                }
                a {
                    background: none !important;
                    &.bx-prev,
                    &.bx-next {
                        text-indent: 0;
                        width: 50px;
                        height: 50px;
                        overflow: hidden;
                        margin-top: -25px;
                        &:before {
                            .icon;
                            color: @textcolor;
                            .font-size(50);
                            width: 50px;
                            height: 50px;
                            overflow: hidden;
                        }
                    }
                    &.bx-prev {
                        left: -100px;
                        &:before {
                            content: '\e901';
                        }
                    }
                    &.bx-next {
                        right: -50px;
                        &:before {
                            content: '\e900';
                        }
                    }
                }
            }

        }
    }
    > section.partner-block-list {
        article {
            .make-sm-column(12);
            .make-md-column(8);
            .partners-map {
                height: 600px;
                margin-bottom: 30px;
            }
        }
        .partner-header {
        }
        .partners-list {
            .clearfix;
            margin: 0 -15px;
            padding: 0;
            .partner-item {
                .make-sm-column(4);
                margin-bottom: 30px;
                @media @small, @medium, @large {
                    &:nth-child(3n+1) {
                        clear: both;
                    }
                }
                a {
                    display: block;
                    color: @textcolor;
                    b {
                        color: @darkblue;
                    }
                    &:hover {
                        text-decoration: none;
                        color: @darkblue;
                        b {
                            color: @blue;
                        }
                    }
                }
            }
        }
        aside {
            @media @small {
                display: none;
            }
            @media @mobile {
                //display: none;
                margin-top: 30px;
            }
            .make-sm-column(4);
            h4 {
                color: @white;
                .font-size(26);
                font-weight: normal;
                margin: 0 0 20px 0;
            }
            .zoeken {
                margin-bottom: 30px;
                .clearfix;
                background-color: @blue-bg;
                figure {
                    .make-sm-column(4);
                    padding: 0;
                    @media @mobile {
                        display: none;
                    }
                }
                form {
                    .make-xs-column(12);
                    .make-sm-column(8);
                    padding: 15px;
                }
            }
            .worden {
                .clearfix;
                background-color: @green-bg;
                h4 {
                    padding: 15px 15px 0 15px;
                }
                figure {
                    .make-xs-column(4);
                    @media @small {
                        display: none;
                    }
                    padding: 15px;
                }
                .info {
                    .make-xs-column(8);
                    .make-sm-column(12);
                    .make-md-column(8);
                    padding: 15px;
                    color: @white;
                }
            }
        }
    }
    > section.news-home {
        .col1 {
            @media @small {
                .make-sm-column(10);
                .col-centered;
            }
            .make-md-column(8);
            .main-news {
                padding: 15px;
                margin-bottom: 15px;
                figure {
                    margin-bottom: 15px;
                    text-align: center;
                }
                .msg-type {
                    text-transform: uppercase;
                    color: @color2;
                }
                @media @mobile {
                    padding: 0;
                }
            }
            .home-news {
                background-color: @lightblue;
                .bericht {
                    overflow: hidden;
                    padding: 15px;
                    height: 100%;
                }
                margin-bottom: 15px;
                .msg-type {
                    text-transform: uppercase;
                    color: @darkblue;
                }
                height: 300px;
                max-height: 300px;
                @media @mobile {
                    height: auto;
                    max-height: none;
                    margin: 0 -15px;
                }
                figure {
                    width: 200px;
                    height: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                    float: right;
                    @media @mobile {
                        display: none;
                    }
                }
                h3 {
                    color: @darkblue;
                }
            }
            a {
                &:before {
                    content: '+ ';
                }
            }
        }
        .col2 {
            .make-sm-column(12);
            .make-md-column(4);
            padding: 0;
            .small-headline {
                @media @small {
                    .make-sm-column(4);
                    height: auto;
                    max-height: none;
                }
                margin-bottom: 15px;
                height: 300px;
                max-height: 300px;
                figure {
                    width: 100px;
                    height: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
                &.nieuws {
                    background-color: @lightblue;
                    figure {
                        float: left;
                    }
                    h3 {
                        color: @darkblue;
                    }
                    @media @small {
                        background: none;
                    }
                }
                &.project {
                    background-color: @grey-bg;
                    figure {
                        float: right;
                    }
                    h3 {
                        color: @textcolor;
                    }
                    @media @small {
                        background: none;
                    }

                }
                .bericht {
                    overflow: hidden;
                    padding: 15px;
                    height: 100%;
                    h3 {
                        margin: 0;
                    }
                }
                .msg-type {
                    text-transform: uppercase;
                    color: @color1;
                }
                a {
                    &:before {
                        content: '+ ';
                    }
                }
            }
            .twitter-feed {
                @media @small {
                    .make-sm-column(4);
                    .tweet {
                        display: none;
                        &:nth-child(2) {
                            display: block;
                        }
                    }
                }
                &:extend(.content .twitter-feed all);
                padding: 15px;
                background-color: @grey-bg;
                @media @small {
                    background: none;
                }

            }
        }
    }
    > section.partner-block-detail {
        article {
            .make-sm-column(8);
            .make-sm-column-pull(4);
            .gmap-partner {
                height: 400px;
              margin-bottom: 30px;
            }
            .headline {
                margin-bottom: 30px;
                .clearfix;
                .thumb {
                    float: right;
                    margin-left: 30px;
                }
                .text {
                    overflow: hidden;
                    a {
                        &:extend(.zerniq_wysiwyg a.plus all);
                    }
                }
            }
            .partners-list {
                &:extend(.content > section.partner-block-list .partners-list all);
            }
            .diensten-list {
                .make-row();
            }
            .dienstenlink {
                .make-sm-column(6);
                margin-bottom: 30px;
                @media @mobile {
                    position: relative;
                    margin-bottom: 0;
                    &:after {
                        position: absolute;
                        bottom: 1px;
                        content: '';
                        left: 10%;
                        right: 10%;
                        border-top: 1px solid @darkblue;
                    }
                    &:last-child {
                        &:after {
                            content: none;
                        }
                    }
                }
                @media @small, @medium, @large {
                    &:nth-child(2n+1) {
                        clear: both;
                    }
                }
                a {
                    display: table;
                    width: 100%;
                    height: 60px;
                    p {
                        display: table-cell;
                        vertical-align: middle;
                        text-align: center;
                    }
                    background-color: @lightblue;
                    color: @darkblue;
                    &:hover {
                        color: @white;
                        text-decoration: none;
                        background-color: @darkblue;
                    }
                }
            }
        }
        aside {
            .make-sm-column(4);
            .make-sm-column-push(8);
            figure {
                padding: 0;
                margin-bottom: 30px;
                text-align: center;
            }
            .contact {
                padding: 15px;
                background-color: @blue;
                color: @white;
                a {
                    color: @contrast2;
                    &:hover {
                        color: @white;
                    }
                }
                img {
                    margin: 0 -15px;
                }
                &.subs {
                    margin-bottom: 30px;
                }
            }
            .visual {
                margin-bottom: 30px;
            }
            .nieuwsbrief {
                padding: 15px;
                margin-bottom: 30px;
            }
            .worden {
                &:extend(.content > section.partner-block-list aside .worden all);
                h4 {
                    &:extend(.content > section.partner-block-list aside h4 all);
                }
            }
        }
    }
    > section.news-all {
        article {
            @media @small {
                .make-sm-column(10);
                .col-centered;
            }
            .make-md-column(8);
            .main-news {
                &:extend(.content > section.news-home .col1 .main-news all);
                padding: 0;
            }
        }
        aside {
            @media @small {
                .make-sm-column(10);
                .col-centered;
            }
            .make-md-column(4);
        }
        .twitter-feed {
            &:extend(.content .twitter-feed all);
            > div {
                background-color: @white;
                padding: 15px;
            }
        }
        .pagenav {
            max-width: 200px;
            margin: 0 auto;
        }
        .newslink {
            .make-sm-column(4);
            margin-bottom: 30px;
            @media @small, @medium, @large {
                &:nth-child(3n+1) {
                    clear: both;
                }
            }
            figure {
                height: 200px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: top center;

            }
            span {
                padding: 15px 15px 45px 15px;
                background-color: @white;
                time {
                    text-transform: uppercase;
                    color: @color1;
                }
                h3 {
                    color: @darkblue;
                    @media @small {
                        word-break: break-all;
                    }
                }
                p {
                    color: @textcolor;
                }
                display: block;
                position: relative;
                &:after {
                    position: absolute;
                    bottom: 15px;
                    left: 15px;
                    right: 15px;
                    content: '+ Lees meer';
                    color: @color1;
                }
            }
            &:hover {
                text-decoration: none;
                span {
                    time {
                        color: @color2;
                    }
                    &:after {
                        color: @color2;
                    }
                }
            }
        }
    }
    > section.diensten-list {
        .dienstenlink {
            .make-sm-column(4);
            margin-bottom: 30px;
            @media @mobile {
                position: relative;
                margin-bottom: 0;
                &:after {
                    position: absolute;
                    bottom: 1px;
                    content: '';
                    left: 10%;
                    right: 10%;
                    border-top: 1px solid @darkblue;
                }
                &:last-child {
                    &:after {
                        content: none;
                    }
                }
            }
            @media @small, @medium, @large {
                &:nth-child(3n+1) {
                    clear: both;
                }
            }
            a {
                display: table;
                width: 100%;
                height: 60px;
                p {
                    display: table-cell;
                    vertical-align: middle;
                    text-align: center;
                }
                background-color: @lightblue;
                color: @darkblue;
                &:hover {
                    color: @white;
                    text-decoration: none;
                    background-color: @darkblue;
                }
            }
        }
    }
    > section.diensten-item {
        article {
            .make-sm-column(8);
            div.partners-list {
                .clearfix;
                ul {
                    @media @small, @medium, @large {
                        width: 50%;
                        float: left;
                    }
                    .no-bullets;
                    a {
                        &:extend(.zerniq_wysiwyg a.plus all);
                        display: block;
                        b {
                            font-weight: 400;
                        }
                        span {
                            display: none;
                        }
                    }
                }
            }
          .partners-map {
            height: 600px;
            margin: 30px 0;
          }
        }
        aside {
            .make-sm-column(4);
            padding: 15px;
            background-color: @grey-bg;
            figure {
                margin: -15px -15px 30px -15px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
                height: 300px;
            }
            h3 {
                color: @darkblue;
                margin: 0;
            }
            ul {
                .no-bullets;
                margin-bottom: 30px;
                a {
                    &:extend(.zerniq_wysiwyg a.plus all);
                    display: block;
                }
            }
        }
    }
    > section.calculator {
        article {
            .make-sm-column(8);
            #dak-map {
                height: 500px;
            }
        }
        aside {
            .make-sm-column(4);
        }
    }
    > section.timeline-block {
        /* -----------------------------------------------
         * Timeline
         * --------------------------------------------- */

        padding: 30px 0;

        .timeline {
            list-style: none;
            padding: 10px 0;
            position: relative;
            font-weight: 300;
            &:before {
                top: 0;
                bottom: 0;
                position: absolute;
                content:" ";
                width: 2px;
                background: #6f9ec5;
                left: 50%;
                margin-left: -1.5px;
            }
            > li {
                margin-bottom: 20px;
                position: relative;
                width: 50%;
                float: left;
                clear: left;
                &:before, &:after {
                    content:" ";
                    display: table;
                }
                &:after {
                    clear: both;
                }
                > .timeline-panel {
                    width: calc(100% - 25px);
                    width: -moz-calc(100% - 25px);
                    width: -webkit-calc(100% - 25px);
                    float: right;
                    margin-right: 45px;
                    border: 1px solid #dcdcdc;
                    background: #ffffff;
                    position: relative;
                    &:before {
                        position: absolute;
                        top: 26px;
                        right: -15px;
                        display: inline-block;
                        border-top: 15px solid transparent;
                        border-left: 15px solid #dcdcdc;
                        border-right: 0 solid #dcdcdc;
                        border-bottom: 15px solid transparent;
                        content:" ";
                    }
                    &:after {
                        position: absolute;
                        top: 27px;
                        right: -14px;
                        display: inline-block;
                        border-top: 14px solid transparent;
                        border-left: 14px solid #ffffff;
                        border-right: 0 solid #ffffff;
                        border-bottom: 14px solid transparent;
                        content:" ";
                    }
                }
                > .timeline-badge {
                    color: #ffffff;
                    width: 30px;
                    height: 30px;
                    line-height: 50px;
                    text-align: center;
                    position: absolute;
                    top: 30px;
                    right: -15px;
                    z-index: 100;
                    > a {
                        color: #1d5980 !important;
                        &:after {
                            .icon;
                            content: '\e909';
                            border-radius: 50%;
                            display: block;
                            width: 30px;
                            height: 30px;
                            text-align: center;
                            line-height: 30px;
                            background-color: @color1;
                            color: @white !important;
                        }
                        &:hover {
                            color: #dcdcdc !important;
                        }
                    }
                }
                &.timeline-inverted {
                    float: right;
                    clear: right;
                    > .timeline-badge {
                        left: -15px;
                    }
                    > .timeline-panel {
                        float: left;
                        margin-left: 45px;
                        margin-right: 0;
                        &:before {
                            border-left-width: 0;
                            border-right-width: 15px;
                            left: -15px;
                            right: auto;
                        }
                        &:after {
                            border-left-width: 0;
                            border-right-width: 14px;
                            left: -14px;
                            right: auto;
                        }
                    }
                }
                &:nth-child(2) {
                    margin-top: 60px;
                }

            }
            .timeline-title {
                margin-top: 0;
                color: inherit;
            }
            .timeline-heading h4 {
                font-weight: 400;
                padding: 0 15px;
                margin: 15px 0;
                color: #1d5980;
            }
            .timeline-body {
                min-height: 20px;
                padding: 0 15px;
            }
            .timeline-body > p, .timeline-body > ul {
                //padding: 10px 15px;
                //margin-bottom: 0;
            }
            .timeline-footer {
                padding: 5px 15px;
                background-color:#f4f4f4;
            }
            .timeline-footer p { margin-bottom: 0; }
            .timeline-footer > a {
                cursor: pointer;
                text-decoration: none;
            }
        }



        .no-float {
            float: none !important;
        }
        @media @mobile {
            ul.timeline:before {
                left: 40px;
            }
            ul.timeline > li {
                margin-bottom: 0px;
                position: relative;
                width:100%;
                float: left;
                clear: left;
            }
            ul.timeline > li > .timeline-panel {
                    width: auto;
            }
            ul.timeline > li > .timeline-badge {
                left: 5px;
                margin-left: 0;
                top: 25px;
            }
            ul.timeline > li > .timeline-panel {
                float: none;
                margin-right: 15px;
                margin-left: 60px;
            }
            ul.timeline > li > .timeline-panel:before {
                border-left-width: 0;
                border-right-width: 15px;
                left: -15px;
                right: auto;
            }
            ul.timeline > li > .timeline-panel:after {
                border-left-width: 0;
                border-right-width: 14px;
                left: -14px;
                right: auto;
            }
            .timeline > li.timeline-inverted {
                float: left;
                clear: left;
                margin-top: 30px;
                margin-bottom: 30px;
            }
            .timeline > li.timeline-inverted > .timeline-panel {
                float: none;
                margin-right: 15px;
                margin-left: 60px;
            }
            .timeline > li.timeline-inverted > .timeline-badge {
                left: 10px;
            }
        }
    }
}

