section.quotes-220 {
    .lh-background-image(linear-gradient(to bottom, @darkblue 0%, @darkblue-dark 100%) );

    .col1, .block-title {
        .make-xs-column(12);
    }
    .bx-wrapper {
        margin: 0;
    }
    .bx-controls-direction {
        a {
            background: none !important;
            &.bx-prev,
            &.bx-next {
                text-indent: 0;
                width: 50px;
                height: 50px;
                overflow: hidden;
                margin-top: -25px;
                &:before {
                    .icon;
                    color: @white;
                    .font-size(50);
                    width: 50px;
                    height: 50px;
                    overflow: hidden;
                }
            }
            &.bx-prev{
                left: 0;
                &:before {
                    content: '\e901';
                }
                @media @mobile {
                    left: -15px;
                }
            }
            &.bx-next{
                right: 0;
                &:before {
                    content: '\e900';
                }
                @media @mobile {
                    right: -15px;
                }
            }
        }
    }

    .quote-item {
        text-align: center;
        cursor: pointer;
        .name {
            color: @color2;
            .font-size(22);
        }
        p {
            .font-size(28);
            color: @white;
        }
        a {
            display: block;
            text-decoration: none;
        }
        @media @mobile {
            padding: 0 30px;
        }
    }
}
