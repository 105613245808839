
.kop {
    color: @titlecolor;
    font-family: @titlefont;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 1em;
}

.subkop {
    color: @color1;
    font-family: @subtitlefont;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 1em;
}

.zerniq_wysiwyg {
    font-family: @contentfont;
    line-height: 1.8;
    color: @textcolor;

    h1, h2 {
        .kop;
    }
    h3, h4 {
        .subkop;
    }
    h1 {
        .font-size(42);
        margin: 0 0 0.6em 0;
        @media @phone, @xsmall {
            .font-size(24);
        }
        & + h1,
        & + h2 {
            margin-top: -0.5em;
        }
    }

    h2 {
        .font-size(30);
        margin: 0 0 0.6em 0;
        @media @phone, @xsmall {
            .font-size(20);
        }
        & + h1,
        & + h2 {
            margin-top: -0.5em;
        }
    }

    h3, h4 {
        .font-size(24);
        margin: 0 0 1em 0;

        @media @phone, @xsmall {
            .font-size(22);
        }
    }
    h4 {
        color: @textcolor;


    }

    h5, h6 {
        //.kop;
        color: @textcolor !important;

        &.center-heading {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .block-title {
        .kop;
        .font-size(36);
        text-align: center;
        color: @color1;
        margin: 10px 0 15px 0;

        @media @phone, @xsmall {
            .font-size(24);
        }
    }

    a {
        color: @color1;

        &:hover {
            color: @color1;
            text-decoration: underline;
        }

        &[href ^='https://www.linkedin'],
        &[href ^='https://twitter'] {
            &:not(.no-icon){
                display: inline-block;
                overflow: unset;
                text-indent: -9999px;
                &:before {
                    .icon;
                    float: left;
                    margin: 0;
                    display: inline-block;
                    text-indent: 0;
                    margin-right: 5px;
                    margin-top: 5px;
                }
            }
            &.keep-text {
                text-indent: 0;
                &:before {
                    float: none;
                    margin-top: 0;
                }
            }
        }
        &[href ^='https://twitter'] {  &:not(.no-icon) { &:before { content: '\e907' !important;}  } }
        &[href ^='https://www.linkedin'] {  &:not(.no-icon) { &:before { content: '\e906' !important;} } }
    }


    img {
        max-width: 100%;
        //height: auto !important;
    }

    p {
        margin: 0 0 1.3em 0;
    }

    p + ul,
    p + ol {
        margin-top: -1.5em;
    }

    time {
        display: block;
        margin-bottom: 10px;
    }
    /*
    ol {
        margin: 0;
        padding: 0;
        list-style-type: none;
        counter-reset: item;

        li {
            position: relative;
            margin: 0;
            padding-left: 50px;
            color: @color1;
            min-height: 50px;
            counter-increment: item;
            .font-size(20);
            padding-top: 2px;

            &:before {
                position: absolute;
                left: 0;
                top: 0;
                content: counters(item,".") " ";
                width: 40px;
                height: 40px;
                .lh-border-radius(50%);
                background: @color1;
                color: @white;
                .font-size(18);
                font-weight: 400;
                text-align: center;
                padding-top: 3px;
            }

        }
    }
    */
    ol {
        margin: 0 0 1.3em 0;
    }
    ul {
        margin: 0 0 1.3em 0;
        @media @phone {
            padding-left: 15px;

            ul {
                padding-left: 15px !important;
            }
        }

        li {
            position: relative;
        }
        &.filelist {
            list-style-type: none;

            padding-left: 5px;
            li {
                a {
                    padding: 5px 0;
                    display: inline-block;
                }
                &:before {
                    content: none;
                }
            }
        }
        &.linklist {
            margin: 1.3em auto;
            .lh-border-radius(8px);
            padding: 0;
            width: 300px;
            max-width: 100%;
            li {
                background-color: #e7e9f0;
                a {
                    display: block;
                    padding: 10px;
                    text-align: center;
                    color: @grey;
                }
                &:hover {
                    background-color: fade(@color1, 50%);
                    a {
                        color: @white;
                        text-decoration: none;
                    }
                }
                &:before {
                    content: none;

                }
            }
        }
    }



    strong {
        font-weight: bold;
    }

    .intro {
        .font-size(18);
        line-height: 1.67;
        font-weight: normal;
    }

    .source {
        font-style: italic;
        font-size: 80%;
    }

    /*
    blockquote {
        display: block;
        text-align: center;
        background: @color1;
        color: @white;
        font-weight: 400;
        margin: 0;
        padding: 25px 25px;
        border: none;
        .font-size(28);
        .lh-border-radius(10);
        line-height: 1.25;

        @media @phone, @xsmall {
            .font-size(22);
        }

        &:before {
            content: '\201C';
        }
        &:after {
            content: '\201D';
        }
    }
    */
    p.no-overflow {
        overflow: hidden;
    }
    img.logo-right {
        float: right;
        margin-left: 15px;

    }
    a.button,
    a.knop,
    button,
    input[type=reset],
    input[type=submit]{
        display: inline-block;
        padding: 5px 35px;
        background-color: @color2;
        color: @white;
        text-decoration: none;
        font-weight: 700;
		text-transform: uppercase;
        border: 0;
        &.groot {
            .font-size(25);
        }

        &:hover {
            background-color: @darkgrey;
        }

        &.arrow {
            &:after {
                .icon;
                content: '\e900';
                vertical-align: middle;
                padding-left: 10px;
            }
        }

        &.blue {
            background-color: @color1;
        }
    }

    a.plus {
        &:before {
            content: '+ ';
        }
    }

    .quote {
        border-top: 2px solid @color1;
        border-bottom: 2px solid @color1;
        padding: 10px 0;
        &.quote-big {
            .font-size(20);
        }
    }

    p.oranje-header {
        text-transform: uppercase;
        color: @color2;
        margin: 0;
        font-weight: bold;
    }

    .oranje-text {
        color: @color2 !important;
    }
    .donkerblauw-text {
        color: @darkblue !important;
    }
    .blauw-text {
        color: @color1 !important;
    }

}
